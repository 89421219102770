.checkbox-toggle-container label {
    cursor: pointer;
}

.checkbox-toggle {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    vertical-align: middle;
    margin-right: 8px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--bulma-border);
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &.round {
            border-radius: 20px;
            &:before {
                border-radius: 50%;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color:var(--bulma-scheme-main);
            -webkit-transition: 0.3s;
            transition: 0.3s;
        }
    }

    input:checked + .slider {
        background-color: var(--bulma-success);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
}
